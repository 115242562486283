import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/syeda/corvette/src/components/post-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Why are some Corvettes classified as Stingrays and some are not?`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a4c8fc9b02207c112a15109d792ea9ac/b17f8/corvette_stingray.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIBAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAACA//aAAwDAQACEAMQAAAByIdE7Bikf//EABkQAQEAAwEAAAAAAAAAAAAAAAEAEBIhQf/aAAgBAQABBQJAuWpeBj//xAAWEQEBAQAAAAAAAAAAAAAAAAAAAhL/2gAIAQMBAT8Btp//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8BjH//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAaEAEAAgMBAAAAAAAAAAAAAAABABEQMUHw/9oACAEBAAE/IVOw1u56uAKgXj//2gAMAwEAAgADAAAAEBs//8QAGBEBAQADAAAAAAAAAAAAAAAAAQARUaH/2gAIAQMBAT8QANhrrf/EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/ECH/xAAaEAEAAgMBAAAAAAAAAAAAAAABABEhMUFR/9oACAEBAAE/EMgUV6Q+12uQXQJxURyS61EiyUGDAT//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "corvette stingray",
            "title": "corvette stingray",
            "src": "/static/a4c8fc9b02207c112a15109d792ea9ac/e5166/corvette_stingray.jpg",
            "srcSet": ["/static/a4c8fc9b02207c112a15109d792ea9ac/f93b5/corvette_stingray.jpg 300w", "/static/a4c8fc9b02207c112a15109d792ea9ac/b4294/corvette_stingray.jpg 600w", "/static/a4c8fc9b02207c112a15109d792ea9ac/e5166/corvette_stingray.jpg 1200w", "/static/a4c8fc9b02207c112a15109d792ea9ac/b17f8/corvette_stingray.jpg 1600w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The name Stingray came from the Chevy Stingray race car, which was first used for the 1963 model year. It was a short-lived trend that eventually ended after the 1968 model year. However, the name was reintroduced in 1969. The word "Stingray" was later dropped, but it was revived on the C4 generation in the C8.
The Corvette SS was meant for racing in the 24 Hours of Le Mans. The project was headed by Zora Arkus-Duntov. GM purchased Mitchell's Concept car and rebadged it with the '63 Corvette and Stingray emblems. Then someone in the Design department decided to split the "Stingray" side script into two parts while keeping the cap S. The new name was born, and Chevrolet was thrilled.`}</p>
    <p>{`The Corvette Stingray was introduced to the public for the first time in 1953. Its first production car had been called the 'Lola', and it was later introduced in the convertible version at the GM Motorama. The Chevrolet Corvette Stingray was launched in the summer of 1963, and the convertible version went on sale a few months later. It was built in Flint, Michigan, St. Louis, Missouri, and Bowling Green, Kentucky. The first cars had four colors: Pennant Blue, Sportsman Red, Black, and Polo White. It sold slowly, but fans continued to use the name, and the car was renamed the "Stingray."`}</p>
    <p>{`The Corvette Stingray was the first production car to use the name. The second-generation model was badged with the same name, and the name stuck until the third-generation was introduced in 1967. The fourth-generation car, which was called the XP87, was badged with the 'Stingray'. Although the 'Stingray' was a base model, it was a two-word version. Among the other differences between the two models are their engine configurations and their weights.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      